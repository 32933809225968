export default {
    "name": "ProductCardMissingInfo",
    "kind": "HoudiniQuery",
    "hash": "85513a259bcf15dda25b379750ac78a9bc9081a8d5147e48a91aa927a26c493b",

    "raw": `query ProductCardMissingInfo($handle: String!, $sdsHandle: String!, $country: CountryCode!, $language: LanguageCode!) @inContext(country: $country, language: $language) {
  product(handle: $handle) {
    is_single: metafield(key: "is_single", namespace: "sprenger") {
      key
      value
      id
    }
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    compareAtPriceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    handle
    title
    options(first: 100) {
      id
      name
      values
    }
    colormap: metafield(key: "colormap", namespace: "sprenger") {
      key
      value
      id
    }
    highlights: metafield(key: "highlights", namespace: "sprenger") {
      key
      value
      id
    }
    imageUrls: metafield(key: "image_urls", namespace: "sprenger") {
      key
      value
      id
    }
    collections(first: 100) {
      edges {
        node {
          title
          handle
          path: metafield(key: "path", namespace: "sprenger") {
            key
            value
            id
          }
          id
        }
      }
    }
    variants(first: 100) {
      edges {
        node {
          id
          title
          sku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
            id
          }
          sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
            key
            value
            id
          }
          imageUrls: metafield(key: "image_urls", namespace: "sprenger") {
            key
            value
            id
          }
          isArchived: metafield(key: "is_archived", namespace: "sprenger") {
            key
            value
            id
          }
          selectedOptions {
            name
            value
          }
          quantityAvailable
          priceV2 {
            amount
            currencyCode
          }
          compareAtPriceV2 {
            amount
            currencyCode
          }
          quantityAvailable
        }
      }
    }
    totalInventory
    id
  }
  sdsProduct: product(handle: $sdsHandle) {
    totalInventory
    variants(first: 100) {
      edges {
        node {
          id
          sku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
            id
          }
          isArchived: metafield(key: "is_archived", namespace: "sprenger") {
            key
            value
            id
          }
          quantityAvailable
        }
      }
    }
    id
  }
}
`,

    "rootType": "QueryRoot",

    "selection": {
        "fields": {
            "product": {
                "type": "Product",
                "keyRaw": "product(handle: $handle)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "is_single": {
                            "type": "Metafield",
                            "keyRaw": "is_single(key: \"is_single\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "priceRange": {
                            "type": "ProductPriceRange",
                            "keyRaw": "priceRange",

                            "selection": {
                                "fields": {
                                    "maxVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "maxVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "minVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "minVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "compareAtPriceRange": {
                            "type": "ProductPriceRange",
                            "keyRaw": "compareAtPriceRange",

                            "selection": {
                                "fields": {
                                    "maxVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "maxVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "minVariantPrice": {
                                        "type": "MoneyV2",
                                        "keyRaw": "minVariantPrice",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "handle": {
                            "type": "String",
                            "keyRaw": "handle",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "visible": true
                        },

                        "options": {
                            "type": "ProductOption",
                            "keyRaw": "options(first: 100)",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "values": {
                                        "type": "String",
                                        "keyRaw": "values",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "colormap": {
                            "type": "Metafield",
                            "keyRaw": "colormap(key: \"colormap\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "highlights": {
                            "type": "Metafield",
                            "keyRaw": "highlights(key: \"highlights\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "imageUrls": {
                            "type": "Metafield",
                            "keyRaw": "imageUrls(key: \"image_urls\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "collections": {
                            "type": "CollectionConnection",
                            "keyRaw": "collections(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "CollectionEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Collection",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "visible": true
                                                            },

                                                            "handle": {
                                                                "type": "String",
                                                                "keyRaw": "handle",
                                                                "visible": true
                                                            },

                                                            "path": {
                                                                "type": "Metafield",
                                                                "keyRaw": "path(key: \"path\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "variants": {
                            "type": "ProductVariantConnection",
                            "keyRaw": "variants(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ProductVariantEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "ProductVariant",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "visible": true
                                                            },

                                                            "sku": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "sds_qty": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sds_qty(key: \"sds_qty\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "imageUrls": {
                                                                "type": "Metafield",
                                                                "keyRaw": "imageUrls(key: \"image_urls\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "isArchived": {
                                                                "type": "Metafield",
                                                                "keyRaw": "isArchived(key: \"is_archived\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "selectedOptions": {
                                                                "type": "SelectedOption",
                                                                "keyRaw": "selectedOptions",

                                                                "selection": {
                                                                    "fields": {
                                                                        "name": {
                                                                            "type": "String",
                                                                            "keyRaw": "name",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "quantityAvailable": {
                                                                "type": "Int",
                                                                "keyRaw": "quantityAvailable",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "priceV2": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "priceV2",

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "compareAtPriceV2": {
                                                                "type": "MoneyV2",
                                                                "keyRaw": "compareAtPriceV2",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "amount": {
                                                                            "type": "Decimal",
                                                                            "keyRaw": "amount",
                                                                            "visible": true
                                                                        },

                                                                        "currencyCode": {
                                                                            "type": "CurrencyCode",
                                                                            "keyRaw": "currencyCode",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "totalInventory": {
                            "type": "Int",
                            "keyRaw": "totalInventory",
                            "nullable": true,
                            "visible": true
                        },

                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "sdsProduct": {
                "type": "Product",
                "keyRaw": "sdsProduct(handle: $sdsHandle)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "totalInventory": {
                            "type": "Int",
                            "keyRaw": "totalInventory",
                            "nullable": true,
                            "visible": true
                        },

                        "variants": {
                            "type": "ProductVariantConnection",
                            "keyRaw": "variants(first: 100)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ProductVariantEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "ProductVariant",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "sku": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "isArchived": {
                                                                "type": "Metafield",
                                                                "keyRaw": "isArchived(key: \"is_archived\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "quantityAvailable": {
                                                                "type": "Int",
                                                                "keyRaw": "quantityAvailable",
                                                                "nullable": true,
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "handle": "String",
            "sdsHandle": "String",
            "country": "CountryCode",
            "language": "LanguageCode"
        },

        "types": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=3cffd148e74cee53a83cf0dfc2f1464c32896167cca8b238e1d0570356078ef5";