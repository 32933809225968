export default {
    "name": "Cart",
    "kind": "HoudiniQuery",
    "hash": "368b13ea6f8aafbec3093f9e7283c53347a3838d5d44a96a1d8a66c7d026b240",

    "raw": `query Cart($id: ID!, $country: CountryCode, $language: LanguageCode) @inContext(country: $country, language: $language) {
  cart(id: $id) {
    ...RequiredCardData
    id
  }
}

fragment RequiredCardData on Cart {
  id
  buyerIdentity {
    customer {
      id
    }
  }
  lines(first: 10) {
    edges {
      node {
        id
        discountAllocations {
          discountedAmount {
            amount
            currencyCode
          }
          __typename
        }
        merchandise {
          ...CartLineItemVariant
          __typename
        }
        quantity
        cost {
          subtotalAmount {
            amount
            currencyCode
          }
          totalAmount {
            amount
            currencyCode
          }
        }
        __typename
      }
      cursor
    }
  }
  discountCodes {
    applicable
    code
  }
  cost {
    subtotalAmount {
      amount
      currencyCode
    }
    totalAmount {
      amount
      currencyCode
    }
    totalDutyAmount {
      amount
      currencyCode
    }
    totalTaxAmount {
      amount
      currencyCode
    }
  }
  __typename
}

fragment CartLineItemVariant on ProductVariant {
  id
  title
  quantityAvailable
  product {
    id
    title
    handle
    sku: metafield(key: "just_sku", namespace: "sprenger") {
      key
      value
      id
    }
    crossSellReferences: metafield(
      key: "crosssell_references"
      namespace: "sprenger"
    ) {
      key
      value
      id
    }
    highlights: metafield(key: "highlights", namespace: "sprenger") {
      key
      value
      id
    }
    collections(first: 100) {
      edges {
        node {
          title
          handle
          description
          path: metafield(key: "path", namespace: "sprenger") {
            key
            value
            id
          }
          id
        }
      }
    }
  }
  priceV2 {
    amount
    currencyCode
  }
  compareAtPriceV2 {
    amount
    currencyCode
  }
  sku: metafield(key: "just_sku", namespace: "sprenger") {
    key
    value
    id
  }
  crossSellReferences: metafield(
    key: "crosssell_references"
    namespace: "sprenger"
  ) {
    key
    value
    id
  }
  image_urls: metafield(key: "image_urls", namespace: "sprenger") {
    key
    value
    id
  }
  sds_qty: metafield(key: "sds_qty", namespace: "sprenger") {
    key
    value
    id
  }
  selectedOptions {
    name
    value
  }
  bundle_desc: metafield(key: "bundle_desc", namespace: "sprenger") {
    key
    value
    id
  }
  unitPrice {
    amount
    currencyCode
  }
  unitPriceMeasurement {
    referenceUnit
    referenceValue
  }
  __typename
}
`,

    "rootType": "QueryRoot",

    "selection": {
        "fields": {
            "cart": {
                "type": "Cart",
                "keyRaw": "cart(id: $id)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "buyerIdentity": {
                            "type": "CartBuyerIdentity",
                            "keyRaw": "buyerIdentity",

                            "selection": {
                                "fields": {
                                    "customer": {
                                        "type": "Customer",
                                        "keyRaw": "customer",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "ID",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "lines": {
                            "type": "BaseCartLineConnection",
                            "keyRaw": "lines(first: 10)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "BaseCartLineEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "BaseCartLine",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "discountAllocations": {
                                                                "type": "CartDiscountAllocation",
                                                                "keyRaw": "discountAllocations",

                                                                "selection": {
                                                                    "fields": {
                                                                        "discountedAmount": {
                                                                            "type": "MoneyV2",
                                                                            "keyRaw": "discountedAmount",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "amount": {
                                                                                        "type": "Decimal",
                                                                                        "keyRaw": "amount",
                                                                                        "visible": true
                                                                                    },

                                                                                    "currencyCode": {
                                                                                        "type": "CurrencyCode",
                                                                                        "keyRaw": "currencyCode",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "__typename": {
                                                                            "type": "String",
                                                                            "keyRaw": "__typename",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "abstract": true,
                                                                "visible": true
                                                            },

                                                            "merchandise": {
                                                                "type": "Merchandise",
                                                                "keyRaw": "merchandise",

                                                                "selection": {
                                                                    "abstractFields": {
                                                                        "fields": {
                                                                            "ProductVariant": {
                                                                                "id": {
                                                                                    "type": "ID",
                                                                                    "keyRaw": "id",
                                                                                    "visible": true
                                                                                },

                                                                                "title": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "title",
                                                                                    "visible": true
                                                                                },

                                                                                "quantityAvailable": {
                                                                                    "type": "Int",
                                                                                    "keyRaw": "quantityAvailable",
                                                                                    "nullable": true,
                                                                                    "visible": true
                                                                                },

                                                                                "product": {
                                                                                    "type": "Product",
                                                                                    "keyRaw": "product",

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "id": {
                                                                                                "type": "ID",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true
                                                                                            },

                                                                                            "title": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "title",
                                                                                                "visible": true
                                                                                            },

                                                                                            "handle": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "handle",
                                                                                                "visible": true
                                                                                            },

                                                                                            "sku": {
                                                                                                "type": "Metafield",
                                                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                                                "nullable": true,

                                                                                                "selection": {
                                                                                                    "fields": {
                                                                                                        "key": {
                                                                                                            "type": "String",
                                                                                                            "keyRaw": "key",
                                                                                                            "visible": true
                                                                                                        },

                                                                                                        "value": {
                                                                                                            "type": "String",
                                                                                                            "keyRaw": "value",
                                                                                                            "visible": true
                                                                                                        },

                                                                                                        "id": {
                                                                                                            "type": "ID",
                                                                                                            "keyRaw": "id",
                                                                                                            "visible": true
                                                                                                        }
                                                                                                    }
                                                                                                },

                                                                                                "visible": true
                                                                                            },

                                                                                            "crossSellReferences": {
                                                                                                "type": "Metafield",
                                                                                                "keyRaw": "crossSellReferences(key: \"crosssell_references\", namespace: \"sprenger\")",
                                                                                                "nullable": true,

                                                                                                "selection": {
                                                                                                    "fields": {
                                                                                                        "key": {
                                                                                                            "type": "String",
                                                                                                            "keyRaw": "key",
                                                                                                            "visible": true
                                                                                                        },

                                                                                                        "value": {
                                                                                                            "type": "String",
                                                                                                            "keyRaw": "value",
                                                                                                            "visible": true
                                                                                                        },

                                                                                                        "id": {
                                                                                                            "type": "ID",
                                                                                                            "keyRaw": "id",
                                                                                                            "visible": true
                                                                                                        }
                                                                                                    }
                                                                                                },

                                                                                                "visible": true
                                                                                            },

                                                                                            "highlights": {
                                                                                                "type": "Metafield",
                                                                                                "keyRaw": "highlights(key: \"highlights\", namespace: \"sprenger\")",
                                                                                                "nullable": true,

                                                                                                "selection": {
                                                                                                    "fields": {
                                                                                                        "key": {
                                                                                                            "type": "String",
                                                                                                            "keyRaw": "key",
                                                                                                            "visible": true
                                                                                                        },

                                                                                                        "value": {
                                                                                                            "type": "String",
                                                                                                            "keyRaw": "value",
                                                                                                            "visible": true
                                                                                                        },

                                                                                                        "id": {
                                                                                                            "type": "ID",
                                                                                                            "keyRaw": "id",
                                                                                                            "visible": true
                                                                                                        }
                                                                                                    }
                                                                                                },

                                                                                                "visible": true
                                                                                            },

                                                                                            "collections": {
                                                                                                "type": "CollectionConnection",
                                                                                                "keyRaw": "collections(first: 100)",

                                                                                                "selection": {
                                                                                                    "fields": {
                                                                                                        "edges": {
                                                                                                            "type": "CollectionEdge",
                                                                                                            "keyRaw": "edges",

                                                                                                            "selection": {
                                                                                                                "fields": {
                                                                                                                    "node": {
                                                                                                                        "type": "Collection",
                                                                                                                        "keyRaw": "node",

                                                                                                                        "selection": {
                                                                                                                            "fields": {
                                                                                                                                "title": {
                                                                                                                                    "type": "String",
                                                                                                                                    "keyRaw": "title",
                                                                                                                                    "visible": true
                                                                                                                                },

                                                                                                                                "handle": {
                                                                                                                                    "type": "String",
                                                                                                                                    "keyRaw": "handle",
                                                                                                                                    "visible": true
                                                                                                                                },

                                                                                                                                "description": {
                                                                                                                                    "type": "String",
                                                                                                                                    "keyRaw": "description",
                                                                                                                                    "visible": true
                                                                                                                                },

                                                                                                                                "path": {
                                                                                                                                    "type": "Metafield",
                                                                                                                                    "keyRaw": "path(key: \"path\", namespace: \"sprenger\")",
                                                                                                                                    "nullable": true,

                                                                                                                                    "selection": {
                                                                                                                                        "fields": {
                                                                                                                                            "key": {
                                                                                                                                                "type": "String",
                                                                                                                                                "keyRaw": "key",
                                                                                                                                                "visible": true
                                                                                                                                            },

                                                                                                                                            "value": {
                                                                                                                                                "type": "String",
                                                                                                                                                "keyRaw": "value",
                                                                                                                                                "visible": true
                                                                                                                                            },

                                                                                                                                            "id": {
                                                                                                                                                "type": "ID",
                                                                                                                                                "keyRaw": "id",
                                                                                                                                                "visible": true
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    },

                                                                                                                                    "visible": true
                                                                                                                                },

                                                                                                                                "id": {
                                                                                                                                    "type": "ID",
                                                                                                                                    "keyRaw": "id",
                                                                                                                                    "visible": true
                                                                                                                                }
                                                                                                                            }
                                                                                                                        },

                                                                                                                        "visible": true
                                                                                                                    }
                                                                                                                }
                                                                                                            },

                                                                                                            "visible": true
                                                                                                        }
                                                                                                    }
                                                                                                },

                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "priceV2": {
                                                                                    "type": "MoneyV2",
                                                                                    "keyRaw": "priceV2",

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "amount": {
                                                                                                "type": "Decimal",
                                                                                                "keyRaw": "amount",
                                                                                                "visible": true
                                                                                            },

                                                                                            "currencyCode": {
                                                                                                "type": "CurrencyCode",
                                                                                                "keyRaw": "currencyCode",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "compareAtPriceV2": {
                                                                                    "type": "MoneyV2",
                                                                                    "keyRaw": "compareAtPriceV2",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "amount": {
                                                                                                "type": "Decimal",
                                                                                                "keyRaw": "amount",
                                                                                                "visible": true
                                                                                            },

                                                                                            "currencyCode": {
                                                                                                "type": "CurrencyCode",
                                                                                                "keyRaw": "currencyCode",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "sku": {
                                                                                    "type": "Metafield",
                                                                                    "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "key": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "key",
                                                                                                "visible": true
                                                                                            },

                                                                                            "value": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "value",
                                                                                                "visible": true
                                                                                            },

                                                                                            "id": {
                                                                                                "type": "ID",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "crossSellReferences": {
                                                                                    "type": "Metafield",
                                                                                    "keyRaw": "crossSellReferences(key: \"crosssell_references\", namespace: \"sprenger\")",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "key": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "key",
                                                                                                "visible": true
                                                                                            },

                                                                                            "value": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "value",
                                                                                                "visible": true
                                                                                            },

                                                                                            "id": {
                                                                                                "type": "ID",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "image_urls": {
                                                                                    "type": "Metafield",
                                                                                    "keyRaw": "image_urls(key: \"image_urls\", namespace: \"sprenger\")",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "key": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "key",
                                                                                                "visible": true
                                                                                            },

                                                                                            "value": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "value",
                                                                                                "visible": true
                                                                                            },

                                                                                            "id": {
                                                                                                "type": "ID",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "sds_qty": {
                                                                                    "type": "Metafield",
                                                                                    "keyRaw": "sds_qty(key: \"sds_qty\", namespace: \"sprenger\")",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "key": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "key",
                                                                                                "visible": true
                                                                                            },

                                                                                            "value": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "value",
                                                                                                "visible": true
                                                                                            },

                                                                                            "id": {
                                                                                                "type": "ID",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "selectedOptions": {
                                                                                    "type": "SelectedOption",
                                                                                    "keyRaw": "selectedOptions",

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "name": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "name",
                                                                                                "visible": true
                                                                                            },

                                                                                            "value": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "value",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "bundle_desc": {
                                                                                    "type": "Metafield",
                                                                                    "keyRaw": "bundle_desc(key: \"bundle_desc\", namespace: \"sprenger\")",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "key": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "key",
                                                                                                "visible": true
                                                                                            },

                                                                                            "value": {
                                                                                                "type": "String",
                                                                                                "keyRaw": "value",
                                                                                                "visible": true
                                                                                            },

                                                                                            "id": {
                                                                                                "type": "ID",
                                                                                                "keyRaw": "id",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "unitPrice": {
                                                                                    "type": "MoneyV2",
                                                                                    "keyRaw": "unitPrice",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "amount": {
                                                                                                "type": "Decimal",
                                                                                                "keyRaw": "amount",
                                                                                                "visible": true
                                                                                            },

                                                                                            "currencyCode": {
                                                                                                "type": "CurrencyCode",
                                                                                                "keyRaw": "currencyCode",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "unitPriceMeasurement": {
                                                                                    "type": "UnitPriceMeasurement",
                                                                                    "keyRaw": "unitPriceMeasurement",
                                                                                    "nullable": true,

                                                                                    "selection": {
                                                                                        "fields": {
                                                                                            "referenceUnit": {
                                                                                                "type": "UnitPriceMeasurementMeasuredUnit",
                                                                                                "keyRaw": "referenceUnit",
                                                                                                "nullable": true,
                                                                                                "visible": true
                                                                                            },

                                                                                            "referenceValue": {
                                                                                                "type": "Int",
                                                                                                "keyRaw": "referenceValue",
                                                                                                "visible": true
                                                                                            }
                                                                                        }
                                                                                    },

                                                                                    "visible": true
                                                                                },

                                                                                "__typename": {
                                                                                    "type": "String",
                                                                                    "keyRaw": "__typename",
                                                                                    "visible": true
                                                                                }
                                                                            }
                                                                        },

                                                                        "typeMap": {}
                                                                    },

                                                                    "fields": {
                                                                        "__typename": {
                                                                            "type": "String",
                                                                            "keyRaw": "__typename",
                                                                            "visible": true
                                                                        }
                                                                    },

                                                                    "fragments": {
                                                                        "CartLineItemVariant": {
                                                                            "arguments": {}
                                                                        }
                                                                    }
                                                                },

                                                                "abstract": true,
                                                                "visible": true
                                                            },

                                                            "quantity": {
                                                                "type": "Int",
                                                                "keyRaw": "quantity",
                                                                "visible": true
                                                            },

                                                            "cost": {
                                                                "type": "CartLineCost",
                                                                "keyRaw": "cost",

                                                                "selection": {
                                                                    "fields": {
                                                                        "subtotalAmount": {
                                                                            "type": "MoneyV2",
                                                                            "keyRaw": "subtotalAmount",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "amount": {
                                                                                        "type": "Decimal",
                                                                                        "keyRaw": "amount",
                                                                                        "visible": true
                                                                                    },

                                                                                    "currencyCode": {
                                                                                        "type": "CurrencyCode",
                                                                                        "keyRaw": "currencyCode",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "totalAmount": {
                                                                            "type": "MoneyV2",
                                                                            "keyRaw": "totalAmount",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "amount": {
                                                                                        "type": "Decimal",
                                                                                        "keyRaw": "amount",
                                                                                        "visible": true
                                                                                    },

                                                                                    "currencyCode": {
                                                                                        "type": "CurrencyCode",
                                                                                        "keyRaw": "currencyCode",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "__typename": {
                                                                "type": "String",
                                                                "keyRaw": "__typename",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "abstract": true,
                                                    "visible": true
                                                },

                                                "cursor": {
                                                    "type": "String",
                                                    "keyRaw": "cursor",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "discountCodes": {
                            "type": "CartDiscountCode",
                            "keyRaw": "discountCodes",

                            "selection": {
                                "fields": {
                                    "applicable": {
                                        "type": "Boolean",
                                        "keyRaw": "applicable",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "cost": {
                            "type": "CartCost",
                            "keyRaw": "cost",

                            "selection": {
                                "fields": {
                                    "subtotalAmount": {
                                        "type": "MoneyV2",
                                        "keyRaw": "subtotalAmount",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "totalAmount": {
                                        "type": "MoneyV2",
                                        "keyRaw": "totalAmount",

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "totalDutyAmount": {
                                        "type": "MoneyV2",
                                        "keyRaw": "totalDutyAmount",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "totalTaxAmount": {
                                        "type": "MoneyV2",
                                        "keyRaw": "totalTaxAmount",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "amount": {
                                                    "type": "Decimal",
                                                    "keyRaw": "amount",
                                                    "visible": true
                                                },

                                                "currencyCode": {
                                                    "type": "CurrencyCode",
                                                    "keyRaw": "currencyCode",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "__typename": {
                            "type": "String",
                            "keyRaw": "__typename",
                            "visible": true
                        }
                    },

                    "fragments": {
                        "RequiredCardData": {
                            "arguments": {}
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "id": "ID",
            "country": "CountryCode",
            "language": "LanguageCode"
        },

        "types": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=83f88720c1123eab3e272b373fdb48db9131106d3253505edefad40fa3916dee";